import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FlattenSimpleInterpolation } from 'styled-components';
import type { ReactNode } from 'react';

import { ErrorMessage } from 'components/Toolkit/Inputs/ErrorMessage';
import { HelpText } from 'components/Toolkit/Inputs/HelpText';
import { Label } from 'components/Toolkit/Inputs/Label';
import { SubText } from 'components/Toolkit/Inputs/SubText';
import { space } from 'helpers/genericStyles';
import type { HeightVariant } from 'components/Toolkit/Inputs/types';
import type { ISpace } from 'helpers/genericStyles';

export interface StrictOption {
  /** Machine value of the option; this is the value passed to `onChange` */
  value: string;
  /** Human-readable text for the option */
  label: string;
  /** Option will be visible, but not selectable */
  disabled?: boolean;
}

export type SelectOption = string | StrictOption;

interface StrictGroup {
  /** Title for the group */
  title: string;
  /** List of options */
  options: StrictOption[];
}

export interface SelectGroup {
  title: string;
  options: SelectOption[];
}

export interface SelectProps extends ISpace {
  /** List of options or option groups to choose from */
  options?: (SelectOption | SelectGroup)[];
  /** Label for the select */
  label?: string;
  /** Disable input */
  disabled?: boolean;
  /** Additional text to aide in use */
  helpText?: string;
  /** ID for form input */
  id?: string;
  /** Name for form input */
  name: string;
  /** Value for form input */
  value?: string;
  /** Does the input have an error */
  hasError?: boolean;
  /** Error message to display beneath the input */
  errorMessage?: string;
  /* Include unselectable placeholder option */
  placeholder?: boolean;
  /** Allow placeholder as an option, value should be 'placeholder' */
  placeholderAsOption?: boolean;
  /** Callback when selection is changed */
  onChange?(selected: string): void;
  /** Callback when select is focussed */
  onFocus?(): void;
  /** Callback when focus is removed */
  onBlur?(): void;
  /** Callback for on click */
  onClick?(): void;
  /** For styling */
  className?: string;
  /** Determines height */
  heightVariant?: HeightVariant;
  /** If to be used in forms */
  willUseSubText?: boolean;
  /** Use react-final-form meta object outside of field */
  metaCallback?: () => void;
  /** For use with text returned from API containing anchor tag/s */
  useDangerouslySetInnerHTML?: boolean;
  /** Initial Value */
  initialValue?: string;
  /** Custom CSS values for select inputs */
  selectStyles?: FlattenSimpleInterpolation;
}

const Wrapper = styled.div`
  ${space}
`;

type StyledSelectProps = {
  hasError: boolean;
  heightVariant?: HeightVariant;
  isPlaceholder: boolean;
  willUseSubText: boolean;
  cssStyles?: FlattenSimpleInterpolation;
};

// https://css-tricks.com/styling-a-select-like-its-2019/
const StyledSelect = styled.select<StyledSelectProps>`
  ${({ theme }) => theme.fontSize.M16};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  border: 1px solid ${({ theme }) => theme.colors.GREY};
  ${({ theme, hasError }) =>
    hasError && `border-color: ${theme.colors.RED_DARK} !important;`}
  padding: ${({ theme }) => `0 ${theme.spacing.M16}`};
  margin-bottom: ${({ theme }) => theme.spacing.S4};
  background-color: ${({ theme }) => theme.colors.WHITE};
  width: 100%;
  display: block;
  height: ${({ heightVariant }) =>
    heightVariant === 'LARGE' ? '48px' : '40px'};
  max-width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.WHITE};
  /* NB: Values must be hardcoded. Theme colour values are not effective here
  since the background-image path is not expecting values with a number sign (#) */
  background-image: ${({ hasError, theme }) =>
    `url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 22 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.702683 0.682328C1.0151 0.369908 1.52163 0.369908 1.83405 0.682328L10.3071 9.1554L18.7802 0.682328C19.0926 0.369908 19.5991 0.369908 19.9116 0.682328C20.224 0.994747 20.224 1.50128 19.9116 1.8137L10.8728 10.8525C10.7228 11.0025 10.5193 11.0868 10.3071 11.0868C10.095 11.0868 9.89147 11.0025 9.74144 10.8525L0.702683 1.8137C0.390264 1.50128 0.390264 0.994747 0.702683 0.682328Z' fill='%23${
      hasError ? 'CC2324' : '222831'
    }'/%3e%3c/svg%3e"),
  linear-gradient(to bottom, ${theme.colors.WHITE} 0%, ${
      theme.colors.WHITE
    } 100%)`};
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/

  background-position: right 1em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 1em auto, 100%;
  color: ${({ theme, isPlaceholder, hasError }) =>
    hasError
      ? theme.colors.RED_DARK
      : isPlaceholder
      ? theme.colors.GREY
      : theme.colors.GREY_DARKER};

  &:focus:not(.focus-visible) {
    outline: none;
  }

  &.focus-visible {
    border-color: ${({ theme }) => theme.colors.BLUE} !important;
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.33496 1.66797L8.00008 8.33309L14.6652 1.66797' stroke='%23D9D9D9' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e "),
      linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    border-color: ${({ theme }) => theme.colors.GREY_LIGHT} !important;

    cursor: not-allowed;
  }
  ${({ cssStyles }) => cssStyles}
`;

const SOption = styled.option`
  display: none;
`;

function Select({
  options: optionsProp,
  label,
  disabled,
  helpText,
  id,
  name,
  value,
  hasError,
  errorMessage,
  placeholder = false,
  placeholderAsOption = false,
  onChange,
  onFocus,
  onBlur,
  onClick,
  className,
  heightVariant = 'DEFAULT',
  willUseSubText = true,
  metaCallback,
  useDangerouslySetInnerHTML,
  initialValue,
  selectStyles,
  ...spaceProps
}: SelectProps) {
  const alwaysSetId = id ? id : name;
  const [focused, setFocused] = useState(false);
  const [isPlaceholder, setIsPlaceholder] = useState<boolean>(placeholder);
  const [initialState, setInitialState] = useState<boolean>(true);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const val = event.currentTarget.value;
    onChange && onChange(val);
    isPlaceholder && setIsPlaceholder(false);
    setInitialState(false);
  }

  // Needed as focus-visible polly was not working as expected
  function handleFocus() {
    setFocused(true);
    onFocus && onFocus();
  }

  // Needed as focus-visible polly was not working as expected
  function handleBlur() {
    setFocused(false);
    onBlur && onBlur();
  }

  useEffect(() => {
    metaCallback && metaCallback();
  }, [hasError]);

  const options = optionsProp || [];
  let normalisedOptions = options.map(normaliseOption);
  const optionsMarkup = normalisedOptions.map((normalisedOption, i) => {
    if (i === 0 && placeholder) {
      return renderPlaceholder(normalisedOption as StrictOption);
    } else {
      return renderOption(name, normalisedOption, i);
    }
  });

  return (
    <Wrapper className={className} {...spaceProps}>
      {label && <Label htmlFor={alwaysSetId}>{label}</Label>}
      <StyledSelect
        id={alwaysSetId}
        name={name}
        value={initialState && initialValue ? initialValue : value}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={onClick}
        hasError={hasError || Boolean(errorMessage && errorMessage.length > 0)}
        className={focused ? 'focus-visible' : ''}
        isPlaceholder={
          isPlaceholder ||
          (placeholderAsOption && (value === 'placeholder' || value === ''))
        }
        willUseSubText={willUseSubText}
        heightVariant={heightVariant}
        cssStyles={selectStyles}
        data-testid={`${name}-select`}
      >
        {optionsMarkup}
      </StyledSelect>
      {willUseSubText && (
        <SubText>
          {errorMessage ? (
            <ErrorMessage text={errorMessage} />
          ) : (
            helpText && (
              <HelpText
                text={helpText}
                useDangerouslySetInnerHtml={useDangerouslySetInnerHTML}
              />
            )
          )}
        </SubText>
      )}
    </Wrapper>
  );
}

function isGroup(option: SelectOption | SelectGroup): option is SelectGroup {
  return (
    typeof option === 'object' && 'options' in option && option.options != null
  );
}

function normaliseOption(
  option: SelectOption | SelectGroup,
): StrictOption | StrictGroup {
  if (typeof option === 'string') {
    return {
      label: option,
      value: option,
    };
  } else if (isGroup(option)) {
    const { title, options } = option;
    return {
      title,
      options: options.map((option) => {
        return option === 'string'
          ? {
              label: option,
              value: option,
            }
          : (option as StrictOption);
      }),
    };
  }
  return option;
}

function renderSingleOption(
  name: string,
  option: StrictOption,
  index: number,
): React.ReactNode {
  const { value, label, ...rest } = option;
  return (
    <option
      data-testid={`${name}-option-${index}`}
      key={index}
      value={value}
      {...rest}
    >
      {label}
    </option>
  );
}

function renderPlaceholder(option: StrictOption): React.ReactNode {
  const { label, ...rest } = option;
  return (
    <SOption key={`${label}-placeholder`} {...rest}>
      {label}
    </SOption>
  );
}

function renderOption(
  name: string,
  optionOrGroup: StrictOption | StrictGroup,
  index: number,
): ReactNode {
  if (isGroup(optionOrGroup)) {
    const { title, options } = optionOrGroup;
    return (
      <optgroup label={title} key={title}>
        {options.map((option, index) =>
          renderSingleOption(name, option, index),
        )}
      </optgroup>
    );
  }

  return renderSingleOption(name, optionOrGroup, index);
}

export { Select };
